export default {
  collection: {
    en: 'Collection',
    ja: 'コレクション'
  },
  connectWalletPrompt: {
    en: 'Please connect wallet to see the collection',
    ja: 'コレクションを見るにはウォレットを接続してください'
  },
  dontHaveNfts: {
    en: "You don't have any NFTs in your collection yet.",
    ja: "あなたのコレクションにはまだ NFT がありません。"
  },
  myCollection: {
    en: "My Collection",
    ja: "私のコレクション"
  }
}
