import React from 'react';
import {ProjectsDict, Token} from "../types";

const ProjectContext = React.createContext<{
  setProjects: (newProjects: ProjectsDict)=>void,
  setTokens: (newTokens: any) => void,
  projects: ProjectsDict,
  tokens: any
}>({
    setProjects: (newProjects: ProjectsDict) => {},
    setTokens: (newTokens: any) => {},
    projects: {},
    tokens: undefined
  }
);

export default ProjectContext;
