import React, {useContext} from "react";
import styled from 'styled-components'
import NotificationIcon from "../../Standard/icons/notificationIcon";
import texts from './localization'
import {localized} from "../../Standard/utils/localized";
import LocaleContext from "../../Standard/LocaleContext";

interface TextProps {
  fontSize: number
  fontWeight: number
  marginBottom?: number
  marginLeft?: number
}

type NotificationPropType = {
  body: string
}

const NotificationDefaultProps = {}

const NotificationContainer = styled.div.attrs<NotificationPropType>(props => ({
  className: props.className
}))`

  &.shown {
    right: 20px;
  }
  
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 16px;
  min-width: 360px;
  max-width: 400px;
  width: max-content;
  height: 125px;
  padding: 13px 24px;
  background: #fff;
  border: 1px solid #04C35C;
  color: #181833;

  @media screen and (max-width: 900px) {
    min-width: 320px;
    width: 300px;
    height: max-content;
  }
`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 12px;
`

const Text = styled.div<TextProps>`
  font-weight: ${p => p.fontWeight};
  font-size: ${p => p.fontSize}px;
  margin-bottom: ${p => p.marginBottom}px;
  margin-left: ${p => p.marginLeft}px;
`

const Notification = (props: NotificationPropType) => {
  const {body} = props
  const {locale} = useContext(LocaleContext)

  return (
    <NotificationContainer>
      <FlexWrapper>
        <NotificationIcon/>
        <Text fontWeight={700} fontSize={20} marginLeft={17}>
          {localized(texts.title, locale)}
        </Text>
      </FlexWrapper>
      <Text fontWeight={400} fontSize={16}>
        {body}
      </Text>
    </NotificationContainer>
  )
};

Notification.defaultProps = NotificationDefaultProps

export default Notification