export default {
  left: {
    en: "left",
    ja: "残り"
  },
  allocate: {
    en: "Allocate up to",
    ja: "まで割り当てる"
  },
  basePrice: {
    en: 'Base price',
    ja: '基本価格'
  },
}
