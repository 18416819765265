import React, {ReactChild} from "react";
import './index.css'
import styled from "styled-components";

type NftProjectCointainerPropType = {
  name: string,
  children: ReactChild | ReactChild[]
}

const NftProjectCointainerDefaultProps = {}

const Container = styled.div`
  width: 100%;
`

const Header = styled.div`
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 30px;
  color: #181833;
`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  flex-wrap: wrap;
  margin-bottom: 30px;
`

const NftProjectContainer = (props: NftProjectCointainerPropType) => {
  const {name, children} = props

  return (
    <Container>
      <Header>{name}</Header>
      <FlexWrapper>
        {children}
      </FlexWrapper>
    </Container>
  )
};

NftProjectContainer.defaultProps = NftProjectCointainerDefaultProps

export default NftProjectContainer