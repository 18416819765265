import React from 'react';

export default () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill="#FFB627"/>
      <path
        d="M9 5.54595L8.53479 9.75561C8.50498 10.0253 8.27708 10.2294 8.00574 10.2294V10.2294C7.73496 10.2294 7.50733 10.0261 7.47686 9.75703L7 5.54595V4C7 3.44772 7.44772 3 8 3V3C8.55228 3 9 3.44772 9 4V5.54595ZM7.99681 11.0327C8.52262 11.0327 8.94888 11.4589 8.94888 11.9848V12.0479C8.94888 12.5737 8.52262 13 7.99681 13V13C7.47099 13 7.04473 12.5737 7.04473 12.0479V11.9848C7.04473 11.4589 7.47099 11.0327 7.99681 11.0327V11.0327Z"
        fill="white"/>
    </svg>
  );
};
