export default {
  tier: {
    en: "Tier",
    ja: "ティア"
  },
  balanceInBUSD: {
    en: "Your balance",
    ja: "あなたのバランス"
  },
  basePrice: {
    en: 'Base price',
    ja: '基本価格'
  },
  maxAllocation: {
    en: "Max allocation",
    ja: "最大配分"
  },
}
