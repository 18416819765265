import React from 'react';

export default () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_316_1751)">
        <path
          d="M14.7866 10.6835L15.617 11.2826C15.7952 11.4112 15.7792 11.6815 15.5871 11.7882L8.48536 15.7306C8.18349 15.8981 7.81651 15.8981 7.51464 15.7306L0.412954 11.7882C0.220783 11.6815 0.204786 11.4112 0.38303 11.2826L1.2134 10.6835L8.00001 14.3756L14.7866 10.6835ZM14.7866 7.10551L15.622 7.70825C15.799 7.83594 15.7848 8.10386 15.5952 8.21207L8.00001 12.5486L0.404767 8.21207C0.215246 8.10386 0.201005 7.83595 0.377985 7.70826L1.2134 7.10551L8.00001 10.7977L14.7866 7.10551ZM8.43157 0.108716L15.5446 4.18038C15.746 4.29568 15.7458 4.5862 15.5443 4.70127L8.00001 9.00868L0.455698 4.70126C0.254163 4.58619 0.253994 4.29568 0.455396 4.18038L7.56761 0.108716C7.69819 0.037581 7.84766 0 8.00001 0C8.15235 0 8.30098 0.037581 8.43157 0.108716ZM8.00001 1.64876L2.86744 4.44108L8.00001 7.23341L13.1326 4.44108L8.00001 1.64876Z"
          fill="currentColor"/>
      </g>
      <defs>
        <clipPath id="clip0_316_1751">
          <rect width="16" height="16" fill="currentColor"/>
        </clipPath>
      </defs>
    </svg>
  );
};
